<!--在线监管-报警管理-监测-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.MN"
            placeholder="编码"
            @keyup.native.enter.stop="getData"
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="排放状态"
            v-model="filter.Status"
            @change="getData"
            clearable
            @clear="filter.Status = null"
          >
            <el-option
              v-for="item in statusOps"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="整体状态"
            v-model="filter.OnlineStatus"
            @change="getData"
            clearable
            @clear="filter.OnlineStatus = null"
          >
            <el-option label="绿" :value="1"/>
            <el-option label="灰" :value="2"/>
            <el-option label="黑" :value="3"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.Owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.AcquitDate"
            type="date"
            value-format="yyyy-MM-dd"
            @change="getData"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="filter.LocaleWithDevice" @change="getData"
          >历史监测点
          </el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
            :loading="exportLoading"
          >导出
          </el-button
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showMN">显示设备编号</el-checkbox>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- table列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        @sort-change="handleSort"
        show-summary
        :summary-method="getSummaries"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="LocaleName"
          sortable="custom"
          label="监测点"
          min-width="140"
          show-overflow-tooltip
          header-align="center"
        >
        </el-table-column>
        <template v-if="!isChengdu">
          <el-table-column
            prop="FanStatus"
            sortable="custom"
            label="风机状态"
            min-width="140"
            align="center"
            show-overflow-tooltip
            header-align="center"
          >
            <template slot-scope="{ row }">
              {{ FanFormatter( row.FanStatus ) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="fan_current"
            sortable="custom"
            label="风机电流值"
            min-width="140"
            align="center"
            show-overflow-tooltip
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="FilterStatus"
            sortable="custom"
            label="净化器状态"
            min-width="140"
            align="center"
            show-overflow-tooltip
            header-align="center"
          >
            <template slot-scope="{ row }">
              {{ FanFormatter( row.FilterStatus ) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="pur_current"
            sortable="custom"
            label="净化器电流值"
            min-width="140"
            align="center"
            show-overflow-tooltip
            header-align="center"
          >
          </el-table-column>
        </template>

        <el-table-column
          v-if="showMN"
          prop="MN"
          sortable="custom"
          label="设备编号"
          align="center"
          min-width="140"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="CEmissions"
          label="油烟折算浓度"
          sortable="custom"
          align="center"
          min-width="140"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{ row.Status === 4 ? "-" : row.CEmissions }}
          </template>
        </el-table-column>
        <el-table-column
          prop="CGranule"
          label="颗粒物折算浓度"
          sortable="custom"
          align="center"
          min-width="140"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{ row.Status === 4 ? "-" : row.CGranule }}
          </template>
        </el-table-column>
        <el-table-column
          prop="CHydrocarbon"
          label="非甲烷总烃折算浓度"
          sortable="custom"
          align="center"
          min-width="160"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{ row.Status === 4 ? "-" : row.CHydrocarbon }}
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterAbnormallyUsed"
          label="不正常使用净化器"
          sortable="custom"
          align="center"
          min-width="160"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.FilterAbnormallyUsed === null
                ? "-"
                : row.FilterAbnormallyUsed
                  ? "不正常"
                  : "正常"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterFanLinkRatio"
          label="联动比"
          min-width="140"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.FilterFanLinkRatio === -1
                ? "-"
                : row.FilterFanLinkRatio * 100 + "%"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Status"
          sortable="custom"
          label="状态"
          align="center"
          min-width="140"
          header-align="center"
        >
          <template slot-scope="scope">
            <img height="40" width="40" :src="getImg('Status', scope.row.Status)"/>
          </template>
        </el-table-column>
        <el-table-column
          label="整体状态"
          align="center"
          min-width="140"
          header-align="center"
        >
          <template slot-scope="scope">
            <img height="40" width="40" :src="getImg('OnlineStatus', scope.row.OnlineStatus)" alt="整体状态"/>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          align="center"
          width="200"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              title="一点一档"
              @click="handlePdfRecordList(scope.row)"
              class="opt el-icon-document"
            />
            <i
              title="清除异常记录"
              v-if="role === 'sa'"
              @click="handleDelRecord(scope.row.MN)"
              class="opt el-icon-delete-solid"
            />
<!--            2024/7/10,暂时取消掉该功能-->
            <i
              title="异常离线处理"
              @click="handleAlarmRecord(scope.row.LocaleId, 2, scope.row.Owner)"
              class="opt fa fa-exclamation-triangle"
            />
<!--            <i-->
<!--              title="报警处理"-->
<!--              @click="handleAlarmRecord(scope.row.LocaleId, 1, scope.row.Owner)"-->
<!--              class="opt fa fa-bell"-->
<!--            />-->
            <i
              title="历史C排"
              @click="
                handleVline(
                  scope.row.LocaleId,
                  scope.row.Owner,
                  scope.row.LocaleEmissionsSill,
                  scope.row.LocaleGranuleSill,
                  scope.row.LocaleHydrocarbonSill
                )
              "
              class="opt el-icon-s-data"
            />
            <i
              title="监测点详情"
              class="opt el-icon-orange"
              @click="handleObservation(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 监测点详情 弹窗 beginning -->
    <observationDialog
      :show="isShowObservationDialog"
      :showData="isShowObservationDialogData"
      @on-close="isShowObservationDialog = false"
    ></observationDialog>
    <!-- 监测点详情 弹窗 end -->

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'PerPage')"
        @current-change="(v) => handlePageChange(v, 'Page')"
        :current-page.sync="filter.Page"
        :page-sizes="[20, 50, 100]"
        :page-size="filter.PerPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--历史C排界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
    >
      <span slot="title"
      ><strong>{{ owner }}</strong></span
      >
      <template v-if="showTyp">
        <el-col :span="24">
          <el-table
            :data="dataRecord.content"
            size="mini"
            border
            highlight-current-row
            v-loading="loading"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="55"
            />
            <el-table-column
              prop="AcquitAt"
              :formatter="dateFormat"
              label="报警日期"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="ProcessAt"
              :formatter="dateFormat"
              label="处理日期"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="ProcessUserName"
              label="处理人"
              align="center"
              header-align="center"
            />
            <el-table-column
              v-if="typ === 1"
              label="原因及处理结果"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.editable"
                  class="input-box"
                  size="mini"
                  v-model="scope.row.Solution"
                />
                <span v-else>{{ scope.row.Solution }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="typ === 2"
              label="原因及处理结果"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-select
                  v-if="scope.row.editable"
                  class="input-box"
                  size="mini"
                  v-model="scope.row.ProcessReason"
                >
                  <el-option
                    label="店家歇业(装修)"
                    value="ShopClosed"
                  ></el-option>
                  <el-option
                    label="监测仪故障"
                    value="DetectorFailure"
                  ></el-option>
                  <el-option
                    label="店家原因导致离线"
                    value="OfflineCausedByShop"
                  ></el-option>
                </el-select>
                <span v-else>{{ scope.row.ProcessReason | filterReason }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="处理"
              align="center"
              header-align="center"
              width="80"
            >
              <template slot-scope="scope">
                <i
                  @click="handleEdit(scope.$index, scope.row)"
                  :class="
                    scope.row.editable
                      ? 'opt fa fa-floppy-o'
                      : 'opt el-icon-edit'
                  "
                />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <!-- 工具条 -->
        <el-col :span="24" class="toolbar">
          <el-pagination
            small
            background
            @size-change="(v) => handlePageChange(v, 'alarmSize')"
            @current-change="(v) => handlePageChange(v, 'alarmPage')"
            :current-page.sync="filter.alarmPage"
            :page-sizes="[20, 30, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataRecord.total"
            style="display: inline-block; margin-left: 15px"
          >
          </el-pagination>
        </el-col>
      </template>
      <template v-else>
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-date-picker
              v-model="filter.peorid"
              type="daterange"
              value-format="timestamp"
              @input="testClick"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="handleExport"
            >导出
            </el-button
            >
          </el-form-item>
        </el-form>
        <!-- <ve-line :data="dataOption" :settings="chartSettings" :grid="grid" :data-empty="dataOption.rows.length===0" /> -->
        <ve-histogram
          :tooltip="tooltip"
          :data="dataOption"
          :mark-line="markLine"
          :extend="extend"
          :colors="['#00A1FF', '#F5B049', '#009999']"
          :settings="chartSettings"
          :grid="grid"
          :data-empty="dataOption.rows.length === 0"
        />
      </template>
    </el-dialog>
    <!-- 生成过的一点一档记录 -->
<!--    <el-dialog-->
<!--      title="一点一档记录"-->
<!--      :visible.sync="pdfRecordVisible"-->
<!--      width="800px"-->
<!--    >-->
<!--      <el-table-->
<!--        :data="pdfList"-->
<!--        size="mini"-->
<!--        border-->
<!--        highlight-current-row-->
<!--        :max-height="clientHeight - 40"-->
<!--        style="width: 100%"-->
<!--      >-->
<!--        <el-table-column-->
<!--          prop="BeginDate"-->
<!--          label="开始时间"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--        />-->
<!--        <el-table-column-->
<!--          prop="EndDate"-->
<!--          label="结束时间"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--        />-->
<!--        <el-table-column-->
<!--          prop="CreatedAt"-->
<!--          label="生成时间"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--        />-->
<!--        <el-table-column label="操作" align="center" header-align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button type="text" size="mini" @click="handleLook(scope.row)"-->
<!--            >查看-->
<!--            </el-button-->
<!--            >-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button size="mini" @click.native="pdfRecordVisible = false"-->
<!--        >关闭-->
<!--        </el-button-->
<!--        >-->
<!--      </div>-->
<!--    </el-dialog>-->
<!--    报警管理里面的一点一档-->
    <el-dialog
      :visible.sync="isShowNotificationDialog"
      style="position: fixed !important;
        top: 50% ;
        left: 50% ;
        width: 110%;
        height: 120%;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transform: translate(-50%, -50%) !important;
        z-index: 9999 !important;"
    >
      <div style="max-height: calc(100vh - 200px);overflow-y: auto;">
        <AlarmDetectorDialog
          :device="device"
        ></AlarmDetectorDialog>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import {mapState} from "vuex";
import "v-charts/lib/style.css";
import VeHistogram from "v-charts/lib/histogram.common";
import _1 from "@/asset/img/1.png";
import _2 from "@/asset/img/2.png";
import _3 from "@/asset/img/3.png";
import _4 from "@/asset/img/4.png";
import {
  join,
  dateFormater,
  stg,
  getUserInfo,
  underline,
  getSinglePname,
  getPname,
  export_json,
  dataFormatter,
  base64ToBlob, ownerJoin,
} from "@/util/index";
import observationDialog from "@/component/ObservationDialog";
import notification7Dialog from "@/component/Notification7Dialog";
import AlarmDetectorDialog from "@/component/AlarmDetectorDialog.vue";

export default {
  components: {AlarmDetectorDialog, VeHistogram, observationDialog, notification7Dialog},
  data() {
    this.chartSettings = {
      // "风机状态","净化器状态",
      metrics: ["油烟折算浓度", "颗粒物折算浓度", "非甲烷总烃折算浓度",],
      dimension: ["采集时间"],
      // xAxisType: 'time',
      yAxisName: ["单位:mg/m³"],
    };
    this.extend = {
      // series: {
      //   barWidth: 20
      // },
      "xAxis.0.axisLabel.rotate": 45,
    };
    this.grid = {
      show: true,
      top: 50,
      left: 10,
      // backgroundColor: '#404a59'
    };
    return {
      tooltip : {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function(params) {
          let result = '';
          // 获取X轴数据，不添加小圆点
          let xAxisData = params[0].axisValue;
          result += xAxisData + '<br/>';
          // 在所有数据之前添加额外的信息，并添加颜色标识
          let FanStatus = this.dataOption.rows[0].风机状态;
          let FilterStatus = this.dataOption.rows[0].净化器状态;
          // 使用param[0]的color，假设所有series颜色相同，否则需要根据具体情况进行调整
          let colorMarkerForExtra = '<span style="display:inline-block;margin-left:-13px;margin-right:5px;border-radius:10px;width:10px;height:10px;"></span>';

          // 添加风机状态信息
          result += colorMarkerForExtra + '风机状态: ' + FanStatus + '<br/>';
          // 添加净化器状态信息，使用同样的颜色标识
          result += colorMarkerForExtra + '净化器状态: ' + FilterStatus + '<br/>';
          // 构建所有 series 的数据信息，并在每个数据前添加对应颜色的小圆点
          for (let i = 0; i < params.length; i++) {
            let param = params[i];
            // 为除了第一个（X轴数据）之外的所有数据添加颜色标识
            let colorMarker = (i !== -1) ? ('<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:' + param.color + '"></span>') : '';
            result += colorMarker + param.seriesName + ': ' + param.value + '<br/>';
          }

          return result;
        }.bind(this),
      },
      device:{},//存储和监管地图一样的数据
      isShowObservationDialog: false, // 是否显示监测点详情弹窗
      isShowObservationDialogData: {}, // 监测点详情弹窗
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      dataOption: {
        columns: [
          "采集时间",
          "油烟折算浓度",
          "颗粒物折算浓度",
          "非甲烷总烃折算浓度",
          "风机状态",
          "净化器状态",
        ],
        rows: [],
      },
      exportLoading: false,
      loading: false,
      datalist: {},
      dataRecord: {},
      pdfRecordVisible: false,
      pdfList: [],
      markLine: null,
      mn: null,
      LocaleId: null,
      role: null,
      deviceInfo: {},
      userInfo: {},
      showTyp: null,
      typ: null,
      owner: null,
      dlg: {
        visible: false,
        type: null,
        title: "",
      },
      filter: {
        Status: null,
        OnlineStatus: null,
        AcquitDate: null,
        SortBy: "status",
        SortMode: "asc",
        alarmPage: 1,
        Page: 1,
        alarmSize: 20,
        PerPage: 20,
      },
      isShowNotificationDialog: false,
      isChengdu: false,
      isShowNotificationDialogData: {
        markLine: {
          symbol: "none",
          itemStyle: {
            normal: {
              lineStyle: {
                type: "solid",
                // color: "red",
              },
              label: {
                // show: false,
                position: "start",
                formatter: "{c} ",
              },
            },
          },
          data: [],
        },
        dataOption: {
          columns: [
            "采集时间",
            "油烟折算浓度",
            "颗粒物折算浓度",
            "非甲烷总烃折算浓度",
            // "风机状态",
            // "净化器状态",
          ],
          rows: [],
        },
      },
      tenMinOption: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["油烟浓度", "颗粒物浓度", "非甲烷总烃浓度"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 143,
          },
        },
        yAxis: {
          type: "value",
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: "平均浓度mg/m³",
        },
        series: [
          {
            name: "油烟浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#00A1FF",
                lineStyle: {
                  type: "solid",
                  color: "#00A1FF",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
          {
            name: "颗粒物浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#F5B049",
                lineStyle: {
                  type: "solid",
                  color: "#F5B049",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
          {
            name: "非甲烷总烃浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#009999",
                lineStyle: {
                  type: "solid",
                  color: "#009999",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
        ],
      },
      showMN: false,
      statusOps: [
        {val: 1, label: "达标"},
        {val: 2, label: "超标"},
        {val: 3, label: "正常离线"},
        {val: 4, label: "异常离线"},
      ],
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree", "areaTree", "user"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
    }),
  },
  filters: {
    filterReason(type) {
      const op = {
        ShopClosed: "店家歇业(装修)",
        DetectorFailure: "监测仪故障",
        OfflineCausedByShop: "店家原因导致离线",
      };
      return op[type];
    },
  },
  created() {
    this.userInfo = getUserInfo();
    if (this.userInfo.Info && this.userInfo.Info.pid && this.userInfo.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
    this.role = stg().getItem("role");
    this.getData();
  },
  mounted() {
  },
  methods: {
    FanFormatter(value) {
      switch (value) {
        case 2:
          return '关'
        case 1:
          return '开'
        case 3:
          return '异常'
        default:
          return '-'
      }
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    // 自定义的合计计算方法
    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 2024/7/10修改监测点合计N/A
        switch (column.property) {
          case 'LocaleName':
          case 'fan_current':
          case 'FanStatus':
          case 'FilterStatus':
          case 'pur_current':
            sums[index] = ''
            return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value) && value !== -1) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    // 一点一档--历史记录
    handlePdfRecordList(data) {
      // this.localeId = row.LocaleId;
      this.device = data
      this.isShowNotificationDialog = true
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive);
    },
    //下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData);
    },
    //下载
    downloadFile(fileName, content) {
      let aLink = document.createElement("a");
      let blob = base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      ); //兼容火狐
    },
    handleObservation(obj) {
      const par = {Param: {id: obj.LocaleId}};
      // const par = {StartAt: (this.filter.alarmPage - 1) * this.filter.alarmSize, Size: this.filter.alarmSize, Typ: this.typ, Param: { mn: this.mn }}
      this.$post("admin/listLocale", par)
        .then((res) => {
          this.isShowObservationDialog = true;
          this.isShowObservationDialogData = res.content[0];
          if (res.content[0].Owner) {
            this.isShowObservationDialogData.Owner = getPname(
              this.customerTree,
              res.content[0].Owner,
              "Org"
            ).join("/");
          }
          if (res.content[0].AreaId) {
            this.isShowObservationDialogData.AreaId = getSinglePname(
              this.areaTree,
              res.content[0].AreaId
            );
          }
        })
        .catch(() => {
        });
    },
    getImg(type, status) {
      if (type === 'Status') {
        switch (status) {
          case 1:
            return _1;
          case 2:
            return _2;
          case 3:
            return _3;
          case 4:
            return _4;
        }
      } else if (type === 'OnlineStatus') {
        switch (status) {
          case 1:
            return _1
          case 2:
            return _3
          case 3:
            return _4
          default:
            return _4
        }
      } else {
        return _4
      }
    },
    getData: function () {
      this.loading = true;
      const para = Object.assign({}, this.filter);
      if (!para.AcquitDate) {
        const now = new Date();
        now.setTime(now.getTime() - 86400000);
        const yesY = now.getFullYear();
        const yesM =
          now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        const yesD = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        this.filter.AcquitDate = `${yesY}-${yesM}-${yesD}`;
        para.AcquitDate = `${yesY}-${yesM}-${yesD}`;
      }
      if (para.Owner && para.Owner.length) {
        para.Owner = join(para.Owner);
      } else if (para.Owner && para.Owner.length === 0) {
        para.Owner = undefined;
      }
      para.LocaleWithDevice = !this.filter.LocaleWithDevice;
      for (let key in para) {
        if (para[key] === "") delete para[key]
      }
      this.$post("admin/queryDataDetectorDaily2", para)
        .then((res) => {
          this.datalist.content = res.content;
          this.datalist.total = res.resultsPageInfo.Total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property]);
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user);
    },
    doneFormatter(r, c, v) {
      if (c.property === "DownDone") {
        return r.DownNum - v;
      }
      return r.ExcessNum - v;
    },
    customerFormatter(r, c) {
      // return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getAlarmRecord() {
      const para = {
        StartAt: (this.filter.alarmPage - 1) * this.filter.alarmSize,
        Size: this.filter.alarmSize,
        Typ: this.typ,
        Param: {LocaleId: this.LocaleId},
      };
      this.$post("admin/listAlarmRecord", para)
        .then((res) => {
          this.dataRecord = res;
        })
        .catch(() => {
        });
    },
    handleOption() {
      this.filter.Page = 1;
      this.filter.SortBy = "status";
      this.filter.SortMode = "asc";
      this.getData();
    },
    handleAlarmRecord(id, typ, owner) {
      this.LocaleId = id;
      this.typ = typ;
      this.showTyp = true;
      this.owner = getPname(this.customerTree, owner, "Org").join("/");
      this.filter.alarmPage = 1;
      this.getAlarmRecord();
      this.dlg.visible = true;
    },
    handleSort(c) {
      if (c.order) {
        this.filter.SortBy = underline(c.prop);
        this.filter.SortMode = c.order.replace(/ending/g, "");
      }
      this.getData();
    },
    testClick(e) {
      this.$nextTick(() => {
        this.filter.peorid = null;
        this.$set(this.filter, "peorid", [e[0], e[1]]);
        this.handleVline();
      });
    },
    handleVline(LocaleId, owner, sill, GranuleSill, HydrocarbonSill) {
      if (typeof LocaleId === "string") {
        this.LocaleId = LocaleId;
        this.markLine = {
          symbol: "none",
          itemStyle: {
            normal: {
              lineStyle: {
                type: "solid",
                // colors: ['#00A1FF', '#F5B049', '#009999'],
              },
              label: {
                // show: false,
                position: "start",
                formatter: "{c} ",
              },
            },
          },
          data: [
            {
              lineStyle: {
                color: "#00A1FF",
              },
              yAxis: sill,
            },
            {
              lineStyle: {
                color: "#F5B049",
              },
              yAxis: GranuleSill,
            },
            {
              lineStyle: {
                color: "#009999",
              },
              yAxis: HydrocarbonSill,
            },
          ],
        };
      }
      if (owner) {
        this.owner = getPname(this.customerTree, owner, "Org").join("/");
      }
      this.showTyp = false;
      const para = {LocaleId: this.LocaleId};
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 30);
        this.filter.peorid[0] = now.getTime();
      }
      para["AcquitAtBegin"] = this.filter.peorid[0] / 1000;
      para["AcquitAtEnd"] = this.filter.peorid[1] / 1000;
      this.$post("admin/queryDataDetectorDaily", para).then((res) => {
        this.dataOption.rows = [];
        let data;
        for (
          let i = para["AcquitAtBegin"];
          i <= para["AcquitAtEnd"];
          i += 86400
        ) {
          data = res.find((e) => e.AcquitAt === i);
          if(data) {
            if(data.FanStatus ===0){
            data.FanStatus = '关'
            }else if(data.FanStatus ===1){
              data.FanStatus = '开'
            }else {
              data.FanStatus = '-'
            }
            if(data.FilterStatus ===0){
              data.FilterStatus = '关'
            }else if(data.FilterStatus ===1){
              data.FilterStatus = '开'
            }else {
              data.FilterStatus = '-'
            }
            this.dataOption.rows.push({
              采集时间: dateFormater(i, false),
              油烟折算浓度: data ? data.CEmissions : 0,
              颗粒物折算浓度: data ? data.CGranule : 0,
              非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0,
              风机状态: data.FanStatus,
              净化器状态: data.FilterStatus,
            });
          }
          
        }
      });
      this.dlg.visible = true;
    },
    handleExport() {
      export_json(
        dateFormater(this.filter.peorid[0] / 1000, false) +
        "至" +
        dateFormater(this.filter.peorid[1] / 1000, false) +
        "-" +
        this.mn,
        this.dataOption.rows,
        {
          油烟折算浓度: "油烟折算浓度",
          颗粒物折算浓度: "颗粒物折算浓度",
          非甲烷总烃折算浓度: "非甲烷总烃折算浓度",
          采集时间: "采集时间",
          // 风机状态: "风机状态",
          // 净化器状态: "净化器状态",
        }
      );
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const para = Object.assign({}, this.filter);
      para.IsDownload = true
      para.Page = 1
      para.PerPage = exportSize
      if (!para.AcquitDate) {
        const now = new Date();
        now.setTime(now.getTime() - 86400000);
        const yesY = now.getFullYear();
        const yesM =
          now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        const yesD = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        para.AcquitDate = `${yesY}-${yesM}-${yesD}`;
      }
      if (para.Owner && para.Owner.length) {
        para.Owner = join(para.Owner);
      } else if (para.Owner && para.Owner.length === 0) {
        para.Owner = undefined;
      }
      para.LocaleWithDevice = !this.filter.LocaleWithDevice;
      for (let key in para) {
        if (para[key] === "") delete para[key]
      }
      this.$post("admin/queryDataDetectorDaily2", para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      });
    },
    handleEdit(i, row) {
      if (row.editable) {
        if (this.typ === 1) {
          this.$post("admin/alarmProcess", {
            id: row.Id,
            solution: row.Solution,
          });
        } else {
          this.$post("admin/updateOfflineAlarmRecordProcessReason", {
            id: row.Id,
            ProcessReason: row.ProcessReason,
          });
        }
      }
      this.$set(
        this.dataRecord.content,
        i,
        Object.assign(row, {
          editable: !row.editable,
          ProcessAt: Date.parse(new Date()) / 1000,
          ProcessUserName: this.userInfo.Name,
        })
      );
    },
    handleDelRecord(mn) {
      this.$confirm("确定清空所有报警吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$get("admin/delAlarmRecord", {mn})
            .then(() => {
              this.handleRefresh();
            })
            .catch(() => {
            });
        })
        .catch(() => {
        });
    },
    handleRefresh() {
      this.filter.Page = 1;
      this.filter.Status = null;
      this.filter.mn = null;
      this.filter.owner = null;
      this.getData();
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      if (field.indexOf("alarm") > -1) {
        this.getAlarmRecord();
      } else {
        this.getData();
      }
    },
    // handlerClose: function () {
    //   this.handleRefresh();
    //   this.filter.peorid = null;
    //   if (this.$refs["form"]) {
    //     this.$refs["form"].resetFields();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}

.fa-bell {
  color: #f56c6c;
}

.el-icon-s-data {
  color: #8896b3;
}

.el-icon-orange {
  color: #409eff;
}

// td .input-box {
//    display: none
// }
td .input-box input {
  border: none;
  width: 100%;
  height: 100%;
}

// 查看图片
.preview_image_box {
  position: relative;
  // z-index: 1;
  .el-button {
    position: relative;
    // z-index: 2;
  }

  .el-image {
    position: absolute;
    // z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .el-image__inner {
    opacity: 0;
  }
}
</style>
